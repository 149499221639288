.Terms_titlediv {
    display: flex;
    justify-content: space-between;
}

.Terms_title {
    font-size: 22px;
    margin: 15px 0;
}

.closepopup {
    color: var(--inputtext) !important;
    cursor: pointer;
}

.Terms_data {
    height: 420px;
    margin: 10px 25px;
    margin-right: 0 !important;
    text-align: left;
    padding-right: 10px;
    overflow: auto;
}

.Terms_data::-webkit-scrollbar {
    width: 5px;
}

.Terms_data::-webkit-scrollbar-track {
    background: #d9d9d9;

}

.Terms_data::-webkit-scrollbar-thumb {
    background: var(--black);

}