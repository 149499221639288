.completebg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/bookingcompletebg.png");


}

.upcomingbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/bookingupcomingbg.png");


}

.booking_status {
    display: flex;
    align-items: center;
    width: 100%;
}

.booking_status_title {
    text-decoration: none;
    color: var(--black);
    border-bottom: 1px solid var(--inputborder);
    padding: 10px 0;
    width: 50%;
    font-size: 14px;
    text-align: center;
}

.selected_title {
    border-bottom: 1px solid var(--black);
}

.mybooking_cardlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mybooking_card {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    width: 48.4%;
    margin: 15px 0;
    position: relative;
    padding-bottom: 40px;

}

.mybooking_caretakerdata {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
        width: 20px;
        height: 15px;
        border-radius: 50%;
    }
}

.mybooking_cardup {
    display: flex;
    gap: 20px;
    margin: 0 6px;
    flex-wrap: wrap;

}

.mybooking_cardinfo {
    margin: 7px 0;

    p {
        color: var(--bookingtitle);
        font-size: 12.5px;
        margin: 4px 0;
    }

    h3 {
        font-size: 13.5px;
        font-weight: normal;
        margin: 4px 0;

    }
}

.mybooking_cardinfo1 {
    margin: 7px 0;

    p {
        color: var(--bookingtitle);
        font-size: 12.5px;
        margin: 4px 0;
        word-wrap: break-word;


    }

    h3 {
        font-size: 13.5px;
        font-weight: normal;
        margin: 4px 0;
        word-wrap: break-word;


    }
}

.mybooking_cardinfo1 {
    margin: 7px 0;

    p {
        color: var(--bookingtitle);
        font-size: 12.5px;
        margin: 4px 0;
    }

    h3 {
        font-size: 13.5px;
        font-weight: normal;
        margin: 4px 0;

    }
}


.mybooking_soon {
    background-color: var(--pink);
    padding: 14px;
    font-size: 13px;
    color: var(--white);
    width: calc(100% - 28px);
    text-align: center;
    margin: 7px 0;
    margin-bottom: 0;

}

.blank_booking {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.mybooking_cancel {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    width: 95%;
    text-align: center;
    cursor: pointer;

}

.mybooking_help {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    width: 95%;
    text-align: center;
    border-top: 1px solid var(--inputborder);
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 0;

    span {
        display: flex;
        align-items: center;
    }

}

@media(max-width:1300px) {
    .mybooking_card {
        width: 98%
    }

    .mybooking_cardup {
        gap: 20px
    }

    .mybooking_help {
        position: relative;
    }
}

@media(max-width:650px) {
    .erning_card {
        width: calc(100% - 20px);
    }

    .booking_status_title {
        font-size: 12px;
    }

    .blank_booking {
        font-size: 12px;
    }

    .mybooking_cardinfo {
        h3 {
            font-size: 11.5px;
            font-weight: normal;
            margin: 4px 0;

        }

        p {
            color: var(--bookingtitle);
            font-size: 10.5px;
            margin: 4px 0;
        }



    }

    .mybooking_cardinfo1 {
        margin: 4px 0;
        padding: 0 5px;


        p {
            color: var(--bookingtitle);
            font-size: 10.5px;
            margin: 4px 0;
        }

        h3 {
            font-size: 11.5px;
            font-weight: normal;
            margin: 4px 0;

        }
    }

    .mybooking_soon {
        font-size: 12px;
        padding: 10px;
        width: calc(100% - 20px)
    }

    .mybooking_help,
    .mybooking_cancel {
        font-size: 12px;
    }

}

@media(max-width:350px) {
    .mybooking_cardup {
        gap: 8px;
    }
}