.rateprofile {
    width: 77px;
    height: 77px;
    border-radius: 50%;
}

.rateprofilename {
    font-size: 15px;
    font-weight: 600;
    margin: 14px 0;
}

.rate_reviewdiv {
    text-align: left;
    margin: 10px 5%;

    textarea {
        border: 1px solid var(--inputborder);
        outline: none;
        border-radius: 6px;
        margin: 10px 0;
        width: 100%;
    }
}

.rate_button {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    height: 42px;
    margin: 10px 5%;
    padding: 0 10px;
    width: 91%;
    outline: none;
    font-size: 14px;
    cursor: pointer;

}