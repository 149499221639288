.Auth_BG {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/authbg.png");
    background-size: cover;
}

.Auth_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 690px;
    background-color: var(--white);
    border-radius: 10px;
    border: none;
    height: 390px;
    display: flex;

}

.Register_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 740px;
    background-color: var(--white);
    border-radius: 10px;
    border: none;
    height: 540px;
    display: flex;
}

.Type_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 690px;
    background-color: var(--white);
    border-radius: 10px;
    border: none;
    height: 390px;

}

.regchange {
    font-size: 12px;
    margin: 0 5px;
    color: var(--black);
    font-weight: 600;
    cursor: pointer;
}

.Auth_img {
    width: 50%;
    border-radius: 10px 0 0 10px;
}

.choosemodal {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin: 10px 0;
}

.choosemodal_div {
    text-align: center;

    p {
        color: var(--fontsocial);
        font-size: 18px;
        font-weight: 600;
        margin: 13px 0;
    }
}

.Type_img {
    border-radius: 50%;
    width: 230px;
    height: 230px;
    cursor: pointer;
    border: 1px solid var(--uploadborder)
}


.Auth_details {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0
}

.auth_title {
    margin: 10px 35px;
    font-size: 23px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;

}

.auth_inputfield {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    height: 45px;
    margin-top: 15px;
    margin-left: 8%;
    margin-right: 8%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding-left: 10px;
}


.auth_inputfield>input {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-size: 15px;
    height: 100%;
}

.auth_inputfield>input::placeholder {
    color: var(--inputtext);
}

.login_forgetpasswordtext {
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 15px 0;

}

.phoneinput {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 13px;
    border: none;
    outline: none;
    width: 90%;
    height: 100px;
}

.auth_submitbutton {
    width: 84%;
    border: none;
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    height: 47px;
    margin: 20px 8%;
    font-size: 15px;
    cursor: pointer;
}


.auth_notaccount {
    font-size: 15px;
    margin: 10px 5px;
    font-weight: 400;
}

.auth_secoundbutton {
    font-weight: bold;
    font-size: 15px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.auth_info {
    font-size: 13px;
    color: var(--inputtext);
    margin: 10px 39px;
}

.validation_error {
    color: red;
    text-align: left;
    margin: 0 30px;
    margin-top: 2px;
    font-size: 10px;
}

@media(max-width:750px) {

    .Auth_main,
    .Type_main,
    .Register_main {
        height: 100vh;
        width: 100%;
        border-radius: 0;
    }

    .Auth_img {
        width: 0;
    }

    .choosemodal {
        flex-direction: column;
        gap: 10px;
    }

    .Auth_details {
        width: 100%;
        height: 80vh;
    }

    .auth_title {
        margin: 60px;
        font-size: 23px;
    }

    .validation_error {
        margin: 0 60px;
        margin-top: 2px !important;
    }

}

@media(max-width:650px) {

    .auth_submitbutton {
        height: 38px;
    }

    .Type_img {
        width: 200px;
        height: 200px;
    }



}