.error_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--landingheader);
    background-image: url("./assets/404.png");

}

.error_heading {
    font-size: 100px;
    color: var(--black);
    margin: 10px;
}

.error_title {
    font-size: 20px;
    margin: 10px;
    color: var(--black);
}

.error_button {
    background-color: var(--black);
    color: var(--white);
    outline: none;
    border: none;
    font-size: 14px;
    padding: 10px 25px;
    border-radius: 20px;
    margin: 10px;
    cursor: pointer;
}