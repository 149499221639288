.Needhelp_input {
    font-size: 14px;
    margin: 15px 25px;
}

.Needhelp_input>input,
.Needhelp_input>textarea {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    margin-top: 10px;
    outline: none;
    font-size: 14px;
    width: calc(100% - 20px);
    min-height: 20px;
    padding: 10px;
}