.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: var(--modalbg);
    cursor: pointer;
}

.notificationmodal_contentdrop {
    display: block;
    position: fixed;
    top: 11vh;
    right: 6.8%;
    background-color: #fff;
    color: var(--black);
    border-radius: 10px;
    width: 270px;
    z-index: 1000000;
}

.notificationmodal_content {
    height: 500px;
    overflow-y: scroll;
    padding: 12px 24px;

}

.notificationmodal_content::-webkit-scrollbar {
    width: 0;
}

.notification_drop {
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    top: -0.7%;
    left: 21%;
    transform: rotate(45deg);
    z-index: 100000000000;

}

.nf_headingdivs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    padding-top: 12px;

}

.nf_heading {
    padding: 6px 0;
    font-size: 17px;

}

.nf_clear {
    font-size: 14px;
    color: var(--appcolor);
}

.nf_describe {
    font-size: 13px;
    color: var(--textgrey);
    margin-bottom: 8px;
}

.notification_detail {
    cursor: pointer;
    display: flex;
    gap: 10px;
    padding: 8px;
    border-radius: 24px;
    margin: 10px 0;
    background: linear-gradient(rgba(20, 77, 73, 0.011)0%, #fff 90%);
    box-shadow: 14px 0 100px 0 rgba(20, 77, 73, 0.1);

}

/* .notification_detail:hover {
    background-color: #ebeaea;
} */

.notification_detail>img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-top: 10px;
}

.nf_alldetail {
    display: flex;
    flex-direction: column;
}

.nf_titlediv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nf_title {
    font-size: 14px;
    font-weight: 600;

}

.nf_msg {
    font-size: 12px;
    color: var(--textgrey)
}

.nf_msg>span {
    color: var(--appcolor);
    border-bottom: 1px solid var(--appcolor);
}

.nf_time {
    font-size: 10px;
    color: var(--textgrey)
}

.nf_view {
    font-size: 12px;
    color: var(--appcolor);
    text-align: right;
}