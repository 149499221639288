.menumodal_content {
    display: block;
    position: absolute;
    top: 12vh;
    right: 18.5%;
    background-color: #fff;
    color: var(--black);
    border-radius: 6px;
    width: 160px;
    padding: 8px 15px;
    z-index: 1000000;
}

.menu_drop {
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    top: -1.3%;
    right: 9%;
    transform: rotate(45deg);
}


.menu_detail {
    cursor: pointer;
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: var(--black);
    margin: 9px 0;
}

@media(max-width:1300px) {
    .menumodal_content {
        right: 14%
    }
}

@media(max-width:1150px) {
    .menumodal_content {
        top: 10vh;
        right: 3.8%;
        padding: 7px 12px;
        width: 140px
    }
}

@media(max-width:900px) {
    .menumodal_content {
        top: 11vh;
        right: 3.5%;
        padding: 5px 10px;
        width: 130px
    }
}

@media(max-width:650px) {
    .menumodal_content {
        top: 8vh;
        right: 6.9%;
        padding: 5px 10px;
        width: 130px
    }
}

@media(max-width:450px) {
    .menumodal_content {
        top: 8vh;
        right: 8%;
        padding: 4px 8px;
        width: 120px
    }

    .menu_detail {
        font-size: 12px;
        margin: 5px 0;
    }
}