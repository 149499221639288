.addressbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/addressbg.png");

}

.address_boxdiv {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    width: 46%
}

.address_title {
    font-size: 14px;
    margin: 8px;
}

.address_titlevalue {
    font-size: 14px;
    margin: 8px;

}

.address_namediv {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    gap: 20px
}

@media(max-width:1150px) {
    .address_namediv {
        flex-direction: column;
    }

    .address_boxdiv {
        width: 80%
    }
}

@media(max-width:650px) {

    .address_boxdiv {
        width: calc(90% - 10px);
        margin-right: 10px;
        padding: 3px;
    }

    .address_title,
    .address_titlevalue {
        font-size: 13px;
        margin: 8px 4px;
    }
}