#install {
    background-color: var(--black);
    display: flex;
    justify-content: space-between;
    color: var(--white);
    font-size: 13px;
    padding: 10px 20px;
    align-items: center;

    button {
        padding: 5px 20px;
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 13px;
        font-family: Poppins;
        cursor: pointer;
    }
}

.seaction1 {
    background-color: var(--landingheader);
    background-image: url("../assets/landing_header.webp");
    background-size: cover;
    height: 700px;
}

@supports not (background-image: url("../assets/landing_header.webp")) {
    .section1 {
        background-image: url("../assets/landing_header.png");
    }
}


.sec1data {
    margin: 6% 19%;
    width: 33%
}

.sec1title {
    font-size: 44px;
    margin: 10px 0;
}

.sec1det {
    color: var(--landingsec1text);
    font-size: 12px;
    line-height: 19px;
}

.sec1know {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px 0;
    cursor: pointer;

}

.sec1caregiverbutton {
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    padding: 12px 20px;
    margin: 20px 7px;
    margin-left: 0 !important;
    font-weight: 500;
    cursor: pointer;
    width: 187px;


}

.sec1clientbutton {
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: var(--white);
    color: var(--black);
    padding: 12px 20px;
    margin: 20px 7px;
    font-weight: 500;
    cursor: pointer;
    width: 187px;



}

.sec1emojidiv {
    background-color: var(--sec3cardbg);
    width: fit-content;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transform: translate(215%, -10%);

    img {
        width: 45px;
        height: auto;
    }

    a {
        font-weight: bold;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: var(--black);


        span {
            font-weight: normal;
            font-size: 13px;
        }

    }
}

.section2 {
    position: relative;
    height: max-content;

}

.sec2videoimg {
    width: 100%;
    height: auto;

}

.sec2playbutton {
    position: absolute;
    top: 40%;
    left: 50%;
    cursor: pointer;

    &&:hover {
        border: none;
        outline: 6px solid var(--btnanimation);
        box-shadow: 0 0 0px 3px var(--pink), 0 0 #0000;
        border-radius: 50%;
        outline-offset: 3px;
        animation-timing-function: ease;
        /* animation: pulse 2s infinite; */

    }
}


.section3 {
    background-color: var(--landingsec3);
    text-align: center;
    margin-top: -4px;
    padding: 60px;
}

.sec3title {
    font-size: 28px;
    color: var(--white);
}

.sec3cardlist {
    display: flex;
    justify-content: space-between;
    margin: 1% 17%;
    margin-top: 3%;
}

.sec3card {
    width: 28%;
    padding: 2%;
    text-align: center;
    background-color: var(--sec3cardbg);
    border-radius: 4px;

    h4 {
        font-size: 18px;
        margin: 10px 0;
    }

    p {
        font-size: 13px;
    }
}

.sec3cardsvg {
    width: 80px;
    height: 80px;
    background-color: var(--landingsec3svg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.section4 {
    padding: 5% 19.5%;
    background-color: var(--sec3cardbg);
    display: flex;
    justify-content: center;
    gap: 50px
}

.sec4data {
    width: 45%;

    h1 {
        font-size: 35px;

    }


    button {
        border: none;
        outline: none;
        border-radius: 6px;
        background-color: var(--black);
        color: var(--white);
        padding: 12px 30px;
        margin: 20px 0;
        cursor: pointer;

    }
}

.sec4supports {
    list-style-type: none;
    color: var(--sec4grey);
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin: 12px 0;

    width: 100%;

    p {
        font-size: 13px;
        width: calc(100% - 16px)
    }
}

.sec4imgs {
    display: flex;
    gap: 15px;

    img {
        width: 65.8%;
        height: auto;
    }
}


.sec4divsimg {
    width: 45%;
    height: auto;
}

.sec4rateimg {
    width: 150px;
    height: auto;
    transform: translate(115%, -79%);
}

.section5 {
    background-color: var(--landingheader);
    text-align: center;
    margin-top: -4px;
    padding: 50px 60px;
}

.sec5_title {
    font-size: 27px;
    margin-bottom: 25px;
    color: var(--black);

}


.sec5_card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 2%;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1% !important;

}

.sec5card_data {
    width: 380px;
    background-color: var(--black);
    color: var(--white);
    padding: 1%;
    border-radius: 5px;

}

.sec5_profilepic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 6px solid var(--landingheader);
    margin-left: -65px;
    background-color: var(--white);

}

.sec5_reviews {
    font-size: 13px;
    color: var(--blurwhite);
    font-weight: 200;
    margin: 5px 0;
    width: 80%;


}

.previmgslider {
    cursor: pointer;
    margin: 2px;

}

.section6 {
    background-color: var(--landingsec3);
    text-align: center;
    margin-top: -4px;
    padding: 60px;
}

.sec6title {
    font-size: 28px;
    color: var(--white);
}

.sec6des {
    font-size: 14px;
    color: var(--white);
    margin: 10px 0;
}

.sec6cardlist {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin: 1% 17%;
    margin-top: 3%;
}

.sec6card {
    width: 45%;
    padding: 2%;
    text-align: center;
    background-color: var(--sec3cardbg);
    border-radius: 4px;

    font-size: 15px;

}

.section7 {
    background-color: var(--sec3cardbg);
    margin-top: -4px;
    padding: 60px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-between;

}

.sec7box {
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 1px 1px 5px var(--black);
}

.sec7title {
    font-size: 18px;
}

.sec7des {
    font-size: 14px;
    margin: 10px 0;
}


/* ------------contact us------------------- */

.contactbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../assets/contactbg.png");


}

.contactdiv {
    background-color: var(--lightorange);
    padding: 20px;
    text-align: center;
}

.contactdiv_title {
    font-size: 27px;
    margin: 20px 0;
}


.contactbookimg {
    width: 26%;
    height: auto;

}

.contactbookdetails {
    text-align: left;
    margin: 15px 0;
    padding-right: 35px;

    h3 {
        font-size: 21px;
        margin: 6px 0;

    }

    p {
        color: var(--landingsec1text);
        font-size: 13px;
        margin: 6px 0;

    }
}


/* ---------------about us-------------------- */
.aboutbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../assets/aboutbg.jpg");


}

.about_data {
    font-size: 15px;
    color: var(--confirmtext);
    margin: 12px 0;
}

/* ---------------------service------------------ */
.servicebg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.441)), url("../assets/servicebg.jpg");
}

.servicedes {
    font-size: 17px;
    font-weight: 600;
    margin: 20px 18%;
}

.servicediv_title {
    font-size: 20px;
    margin: 15px 18%;
}

.servicediv_detail {
    font-size: 16px;
    margin: 15px 18%;
}

.servicediv {
    background-color: var(--lightorange);
    display: flex;
    justify-content: space-between;

    img {
        width: 30%;
        height: auto;

    }
}

.services_buttonlist {
    display: flex;
    margin: 25px 18%;
    gap: 20px
}

.servicecontactinfo {
    margin-left: 10% !important;
    margin: 20px 0;
}

.services_button {
    border: none;
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    padding: 10px;
    margin: 15px 0;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;

}

.service_data {
    font-size: 15px;
    color: var(--confirmtext);
    margin: 15px 18%;
}


/* -----------------------------------how it work-------------------------------------------- */
.howbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../assets/Becamecaretaker4.png");
}

.how_left {
    width: 65%;
    margin: 40px 0;
}

.howtitle {
    font-size: 27px;
    margin: 10px 0;
}

.howdes {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
}

.how_list {
    display: flex;
    gap: 20px;
    padding: 20px 10px;
    border-bottom: 1px solid #e2e2e2;
}

.how_num {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--black);
}

.how_num_info {
    width: calc(100% - 55px);
    font-size: 13px;
    color: var(--black);
}

.terms-policy,
.privacy-policy {
    margin: 10px 25px;
    margin-right: 0 !important;
    text-align: left;
    padding-right: 10px;
    overflow: auto;
}

/* Headings */
.privacy-policy h1 {
    font-size: 17px;
    margin-bottom: 10px;
}

.privacy-policy h2 {
    font-size: 15px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

/* Paragraphs */
.privacy-policy p {
    margin-bottom: 15px;
}

/* List Styling */
.privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
}

.privacy-policy ul li {
    margin-bottom: 10px;
    font-size: 15px;
}

@media(max-width:1550px) {
    .sec1emojidiv {
        transform: translate(215%, -60%);

    }


}

@media(max-width:1350px) {
    .seaction1 {
        height: 600px;
        position: relative;

    }

    .sec1data {
        margin: 3% 17%;
    }

    .sec1title {
        font-size: 30px;
    }

    .sec1caregiverbutton,
    .sec1clientbutton {
        margin: 5px 7px;
        margin-left: 7px !important;
    }

    .sec1emojidiv {
        transform: translate(0%, 0%);
        position: absolute;
        bottom: 15px;

        left: 50%;

    }

    .sec4divsimg {
        width: 50%
    }

    .sec4data {
        width: 45%;

        h1 {
            font-size: 30px;

        }

        p {
            font-size: 16px;
        }

        li {
            list-style-type: none;
            color: var(--sec4grey);
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px 0;
        }

        button {
            border: none;
            outline: none;
            border-radius: 6px;
            background-color: var(--black);
            color: var(--white);
            padding: 10px 30px;
            margin: 15px 0;
            cursor: pointer;

        }
    }

    .sec4imgs .contactbookdetails {
        h1 {
            font-size: 18px;
        }
    }

    .sec4imgs {

        img {
            width: 65.8%
        }
    }


}

@media(max-width:1150px) {

    .sec1data {
        margin: 2% 14%;
        width: 35%
    }

    .sec1title {
        font-size: 30px;
    }

    .sec1caregiverbutton,
    .sec1clientbutton {
        margin: 6px 7px;
    }

    .sec3cardlist,
    .sec6cardlist {
        margin: 1% 12%;
        margin-top: 3%;
    }

    .section4 {
        padding: 5% 15%;
    }


    .sec4data {
        width: 45%;

        h1 {
            font-size: 27px;

        }

        p {
            font-size: 14px;
            margin: 15px 0;
        }

    }

    .contactdiv_title {
        font-size: 22px;
    }


}

@media(max-width:950px) {

    .sec1data {
        margin: 1% 10%;
        width: 40%
    }

    .sec1title {
        font-size: 24px;
        margin: 10px 0;
    }


    .sec1know {
        margin: 5px;
        font-size: 13px;
    }

    .sec1caregiverbutton,
    .sec1clientbutton {
        margin: 6px;
        padding: 8px 15px;
        font-size: 12px;
    }

    /* .section2 {
        height: 430px;
    } */

    .sec3cardlist,
    .sec6cardlist {
        margin: 1%;
        margin-top: 3%;
    }

    .sec3title {
        font-size: 25px;
    }

    .section4 {
        padding: 5% 10%;
    }


    .sec4data {
        width: 40%;

        h1 {
            font-size: 22px;

        }

        button {
            border: none;
            outline: none;
            border-radius: 6px;
            background-color: var(--black);
            color: var(--white);
            padding: 8px 30px;
            margin: 13px 0;
            cursor: pointer;
            font-size: 12px;

        }
    }

    .sec4imgs {
        img {
            width: 55%;
        }
    }

    .sec4rateimg {
        transform: translate(75%, -79%);
    }

    .servicecontactinfo {
        margin-left: 0 !important;
    }

    .servicediv_title {
        margin: 15px 10%;
        font-size: 18px;

    }

    .servicedes {
        margin: 20px 15%;
    }


    .services_buttonlist {
        margin: 25px 10%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .services_button {
        width: 90%;
        margin: 0;
    }

    .contactbookimg {
        width: 220px
    }

    .contactbookdetails {
        padding-right: 0;
        text-align: center;

        h3 {
            font-size: 18px;
        }
    }


    .how_list {
        gap: 15px;
    }

    .how_num {
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    .how_num_info {
        width: calc(100% - 45px);
    }


    .sec5card_data {
        width: 350px;
        font-size: 15px;

    }

    .sec5_profilepic {
        width: 160px;
        height: 160px;

    }

    .sec5_reviews {
        font-size: 12px;

    }



}

@media(max-width:650px) {
    #install {
        gap: 20px
    }

    .seaction1 {
        background-image: url("../assets/landing_header\ -\ mobile.png");
    }

    .sec1title {
        margin-top: 40px;
    }

    .sec1data {
        margin: 2% 5%;
        width: 80%
    }

    .sec1emojidiv {
        display: none;
    }

    .sec1caregiverbutton,
    .sec1clientbutton {
        margin: 6px;
        padding: 8px 15px;
        font-size: 12px;
        width: 70%;
    }

    /* .section2 {
        height: 350px;
    } */

    .section3 {
        padding: 35px;
    }

    .sec3cardlist,
    .sec6cardlist {
        flex-direction: column;
        gap: 12px;
        margin: 1%;
        margin-top: 3%;
    }

    .sec3card {
        width: 100%
    }

    .section4 {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .sec4data,
    .sec4divsimg {
        width: 90%;
        height: max-content;
        position: relative;
    }

    .sec4rateimg {
        bottom: -5%;
        left: 40%;
        position: absolute;
        transform: translate(0%, 0%);
        width: 40%
    }

    .section5 {
        padding: 50px;
    }

    .servicediv>img {
        display: none;
    }

    .servicediv_title {
        margin: 15px 5%;
        font-size: 16px;

    }

    .sec6card {
        width: 96%;
        font-size: 14px;
    }

    .servicedes {
        margin: 20px 12%;
        font-size: 15px;
    }



    .services_buttonlist {
        margin: 25px 5%;
    }

    .howtitle {
        font-size: 19px;
    }

    .howdes {
        font-size: 12px;
    }


    .how_num {
        width: 23px;
        height: 23px;
        font-size: 11px;
    }

    .how_num_info {
        width: calc(100% - 40px);
        font-size: 11px;
    }

    .how_left {
        width: 100%
    }


}

@media(max-width:450px) {
    .sec1data {
        margin: 2% 5%;
        width: 80%
    }

    /* .section2 {
        height: 270px;
    } */

    .sec2playbutton {
        left: 39%
    }

    .sec3title {
        font-size: 22px;
    }

    .section5 {
        padding: 50px 20px;
    }

    .sec5_title {
        font-size: 21px;
    }

    .sec5_card {
        flex-direction: column-reverse;
        margin-bottom: 10px;
    }

    .sec5card_data {
        width: 90%;
        padding: 2%;
        text-align: center;
        padding-top: 80px !important;

    }

    .sec5_reviews {
        width: 100%
    }

    .sec5_profilepic {
        width: 120px;
        height: 120px;
        margin-left: 0;
        margin-bottom: -65px;
        z-index: 10000000;
        border: 4px solid var(--landingheader);
    }

    .about_data {
        font-size: 13px;
    }

    .servicedes {
        margin: 20px 8%;

    }

    .services_buttonlist {
        flex-direction: column;
        gap: 0;
    }

    .services_button {
        font-size: 10px;
        margin: 3px;
    }
}