header {
    height: 354px;
    background-size: cover;
    width: 100%;
    position: relative;
}

.Header_title {
    font-size: 25px;
    color: var(--white);
    margin: 7% 19%;

}

.Headerbar {
    padding: 0.5% 19.5%;
    width: 61%;
    background-color: var(--Headerbg);
}

.Header_list {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
}

.modal-overlay {
    display: none;
}

.Header_logo {
    width: 215px
}

.Header_mobile_logo {
    display: none;
}

.Header_menulist {
    display: flex;
    align-items: center;
    gap: 23px;
    list-style-type: none;

}

.Header_menu {
    text-decoration: none;
    color: var(--white);
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.Headermenu>span {
    line-height: 23px;
}

.Header_menu_button {
    border: none;
    border-radius: 6px;
    background-color: var(--sec3cardbg);
    color: var(--black);
    height: 40px;
    padding: 5px 23px;
    margin: 15px 0;
    font-size: 15px;
    cursor: pointer;


}

.Header_top {
    margin-top: 11%;
}


.Header_searchfield {
    border-radius: 6px;
    /* border: 1px solid #CFCFCF; */
    background-color: var(--Headericon);
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid var(--cardborder);
    width: 231px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px;
    margin: 20px 0;
}

.Header_searchfield>input {
    border: none;
    outline: none;
    width: 90%;
    background-color: var(--Headericon);
}

.Header_searchfield>input::placeholder {
    color: var(--searchinputtext);
}

.Header_login_details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.Header_login_details>img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.toggle {
    display: none;
}

.Header_mobilelist {
    display: none;

}

.header_profileinfodiv {
    display: flex;
    gap: 5px;


}

.header_menuicon>svg {
    display: none;
}

@media(max-width:1300px) {
    .Headerbar {
        padding: 0.5% 15%;
        width: 70%
    }

    .Header_logo {
        width: 200px
    }
}

@media(max-width:1150px) {
    .Headerbar {
        padding: 0.5% 5%;
        width: 90%
    }

    .Header_logo {
        width: 180px
    }

    .Header_menu {
        font-size: 13px;
    }

}

@media(max-width:900px) {
    header {
        height: 190px;
    }

    .Header_menulist {
        display: none;
    }

    .Header_logo {
        width: 170px;
        margin: 10px;
    }

    .header_titlediv {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32%
    }

    .Header_title {
        margin: 20px;
        font-size: 18px;
    }

}

@media(max-width:650px) {
    header {
        height: 150px;
    }

    .Headerbar {
        padding: 0.5% 1%;
        width: 98%
    }

    .Header_menu_button {
        padding: 0 15px;
        font-size: 13px;
        margin: 5px 0;
        height: 30px;
    }

    .header_profileinfodiv {
        display: flex;
        gap: 5px;
        align-items: center;

    }

    .header_menuicon>svg {
        display: block;
    }



    .Header_login_details>img {
        width: 20px;
        height: 20px;
    }

    .Header_menu {
        font-size: 10px;
        align-items: center;
    }

    .Header_title {
        font-size: 16px;
    }

    .Header_logo {
        display: none;
    }

    .Header_mobile_logo {
        display: block;
        width: 50px;
        margin: 5px;
    }

    .Header_mobilelist {
        width: 101%;
        display: flex;
        list-style-type: none;
        align-items: center;
        justify-content: space-between;
    }

    .modal-overlay {
        background-color: var(--sec3cardbg);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        list-style-type: none;
    }

    .Header_mobilemenulist {
        width: 100%;
        max-height: 100%;
        list-style-type: none;

        .Header_menu {
            text-decoration: none;
            color: var(--black);
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin: 25px 0;

        }

        .menucloseicon {
            position: absolute;
            right: 15px;
            top: 20px
        }

        .Headermenu>span {
            line-height: 23px;
        }

    }

}