* {
  margin: 0;
  padding: 0;
  font-family: Be Vietnam Pro;

}

body {
  color: var(--black);

}

:root {
  --black: #312920;
  --footerinfo: #B8B7B2;
  --white: #ffffff;
  --earningstub: #f3f3f3;
  --footerline: #3E362C;
  --footersocial: #42382E;
  --landingheader: #E9CBA6;
  --btnanimation: #bea4846b;
  --landingsec1text: #746655;
  --landingsec3: #534439;
  --landingsec3svg: #91613C;
  --sec3cardbg: #FAE0BD;
  --sec4grey: #5A5553;
  --inputborder: #E3E3E3;
  --inputtext: #ABA69F;
  --confirmtext: #635B53;
  --menuborder: #F1E6D6;
  --menubg: #FDF6EC;
  --green: #25B388;
  --pink: #CDA58A;
  --lightorange: #FAE0BD;
  --uploadborder: #cacaca;
  --bookingtitle: #797269;
  --paymentvalue: #B4ACA2
}