.billingbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/billingbg.png");
}

.bill_table {
    border: 1px solid var(--inputborder);
    border-collapse: separate;
    text-align: left;
    width: 100%;
    border-radius: 6px;
    border-spacing: 0;

    th {
        padding: 10px;
        border-bottom: 1px solid var(--inputborder);
        font-size: 15px;

    }

    td {
        padding: 5px 10px;
        border-bottom: 1px solid var(--inputborder);
        font-size: 14px;
    }
}


.billing_profilediv {}

.billing_profileinfo {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
        width: 17px;
        height: 17px;
        border-radius: 50%;
    }
}

.billing_button {
    padding: 5px 10px;
    background-color: var(--black);
    color: var(--white);
    border-radius: 6px;
    border: none;
    outline: none;
    margin: 5px 0;
}

@media(max-width:900px) {
    .bill_table {

        th {
            padding: 10px;
            font-size: 14px;

        }

        td {
            padding: 5px 10px;
            font-size: 13px;
        }
    }

    .billing_button {
        font-size: 13px;
    }


}

@media(max-width:650px) {
    .bill_table {

        th {
            font-size: 12px;

        }

        td {
            font-size: 11px;
        }
    }

    .billing_button {
        padding: 4px 8px;
        background-color: var(--black);
        color: var(--white);
        border-radius: 6px;
        border: none;
        outline: none;
        margin: 5px 0;
        font-size: 10px;
    }

    .bill_table_over {
        overflow-x: scroll;
    }

    .bill_table_over::-webkit-scrollbar {
        height: 1px
    }

    .bill_table_over::-webkit-scrollbar-track {
        background: var(--white);
    }

    .bill_table_over::-webkit-scrollbar-thumb {
        background: var(--black);
    }


}