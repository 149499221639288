.Navbar {
    padding: 0.5% 19.5%;
    width: 61%;
    background-color: var(--Navbg);
    position: relative;
}

.Nav_list {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
}

.Nav_logo {
    width: 215px;
    height: auto;
}

.Header_mobile_logo {
    display: none;
}

.Nav_menulist {
    display: flex;
    align-items: center;
    gap: 23px;
    list-style-type: none;
}

.Nav_menu {
    text-decoration: none;
    color: var(--black);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.Navmenu>span {
    line-height: 23px;
}

.Nav_menu:hover,
.Nav_menu:hover .arrowsvg {
    color: var(--landingsec3svg);
    fill: var(--landingsec3svg)
}




.Nav_menu_button {
    border: none;
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    height: 40px;
    padding: 5px 23px;
    margin: 15px 0;
    font-size: 15px;
    cursor: pointer;


}

.header_profileinfodiv {
    display: flex;
    gap: 5px;


}

.header_menuicon>svg {
    display: none;
}

@media(max-width:1300px) {

    .Navbar {
        padding: 0.5% 15%;
        width: 70%
    }

    .Nav_logo {
        width: 200px
    }

}

@media(max-width:1150px) {

    .Navbar {
        padding: 0.5% 5%;
        width: 90%
    }

    .Nav_logo {
        width: 180px
    }

    .Nav_menu {
        font-size: 13px;

    }
}

@media(max-width:900px) {
    .Nav_menulist {
        display: none;
    }

    .Nav_logo {
        width: 170px;
        margin: 10px;
    }


}

@media(max-width:650px) {
    .Nav_logo {
        display: none;
    }

    .Nav_menu_button {
        padding: 0 15px;
        font-size: 13px;
        margin: 5px 0;
        height: 30px;
    }

    .header_profileinfodiv {
        display: flex;
        gap: 5px;
        align-items: center;

    }

    .header_menuicon>svg {
        display: block;
    }


    .Nav_mobilemenulist {
        background-color: var(--sec3cardbg);
        width: 100%;

        .Nav_menu {
            text-decoration: none;
            color: var(--black);
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: flex-end;
        }

        .Navmenu>span {
            line-height: 23px;
        }

    }

}