.earningbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/earningbg.png");

}

.earningdays {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    width: 100%
}

.earningdaytitlediv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            cursor: pointer;
        }
    }
}

.earningdaytitle {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 10px;

}

.earningdaysname {
    /* margin: 5px; */
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    width: 11%;


}

.earningdatedata {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin: 15px 0;
    width: 11%;

}

.earningdayhour {
    background-color: var(--earningstub);
    padding: 12px 5px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin: 17px 0;
    cursor: pointer;


}

.erning_card {
    width: 100%;
    border: 1px solid var(--inputborder);
    box-shadow: 0px 7px 5px 0 var(--earningstub);
    border-radius: 6px;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.earningdate {
    width: 15px;
    padding: 5px;
    cursor: pointer;

}

.selectedearndate {
    background-color: var(--black);
    color: var(--white);
    width: 15px;
    padding: 5px;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
}

.selectedearnhour {
    background-color: var(--black);
    color: var(--white);
}

@media(max-width:1350px) {
    .erning_card {
        flex-wrap: wrap;
        align-items: center;
        gap: 10px
    }
}

@media(max-width:650px) {

    .earningdaysname,
    .earningdatedata {
        font-size: 10px;
    }

    .earningdayhour {
        font-size: 8px;
        /* padding: 5px;/ */
        width: calc(100%)
    }
}