.addcard_details {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: calc(100% - 210px)
}


.addcard_date {
    width: 100%;
    margin: 2.5% 8%;
}

.addcard_date>h3 {
    font-size: 15px;
}

.date_field {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    gap: 5%
}

.date_input {
    width: 45%;
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    height: 45px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding-left: 10px;

}


.ElementsApp {
    display: flex !important;
}

@media screen and (max-width: 950px) {
    .details_input {
        padding: 3%;
    }

}

@media screen and (max-width: 650px) {
    .details_input {
        padding: 4%;
    }

    .addcard_details {
        width: calc(100% - 78px);
        margin: 40px 0;
    }

    .date_input {
        height: 38px;
        margin-top: 18px;

    }

}

@media screen and (max-width: 540px) {
    .details_input {
        padding: 5.5%;
    }

    .addcard_header {
        padding: 5% 2%;
        /* height:50px; */
        gap: 35%;
    }




}

@media screen and (max-width: 414px) {
    .details_input {
        padding: 7%;
    }

    .details2_input {
        padding: 7%;
    }

    .addcard_header {
        gap: 30%;
    }

    .addcard_button {
        margin-top: 30%;

    }

}