.Booktype_heading {
    font-size: 16px;
    margin: 25px 0;
}

.Book_type_main {
    margin: 20px 0;
    width: 60%;
}

.Booking_types {
    margin: 15px 0;
    font-size: 14px;
    padding: 15px;
    border: 1px solid var(--black);
    border-radius: 15px;
    cursor: pointer;
}

.Booking_describe {
    font-size: 13px;
    padding: 10px 0;
    color: var(--confirmtext);

}

@media(max-width:900px) {
    .Book_type_main {
        width: 100%
    }
}