.pagedata {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin: 10px 0;
}


.selectedpage {
    border-radius: 5px;
    padding: 7px 12px;
    background-color: var(--black);
    color: var(--white);
    border: none;
    outline: none;

}

.page {
    color: var(--black);
    background-color: var(--inputborder);
    border: none;
    padding: 7px 12px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

}

.page:disabled {
    color: var(--confirmtext);
    background-color: var(--inputborder);
    cursor: not-allowed;

}