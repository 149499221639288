.referbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/refer\ bg.png");

}

.refer_info {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 210px);
}

.refer_img {
    width: 40%;
    height: auto;
    background-color: var(--pink);
}

.refer_left {
    width: 60%;

}

.referinfodiv {
    width: 86%
}

@media(max-width:900px) {
    .refer_info {
        flex-direction: column;
    }

    .refer_left {
        width: 100%
    }

    .refer_img {
        display: none;
    }
}

@media(max-width:650px) {
    .refer_info {
        width: calc(100% - 65px);
        padding: 10px
    }
}