footer {
    background-color: var(--black);
    padding: 4% 21% 1% 19%;

}

.homepage_footer_up {
    display: flex;
    gap: 6%;

}

.homepage_footerdiv1 {
    width: 38%;
}

.homepage_footerdiv2 {
    width: 17%
}

.homepage_footerdiv3 {
    width: 17%
}

.homepage_footerdiv4 {
    width: 23%
}

.homepage_footer_heading {
    color: var(--white);
    font-family: Be Vietnam Pro;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3%;

}

.homepage_footer_info {
    color: var(--footerinfo);
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3%;


}

.homepage_footer_info2 {
    color: var(--footerinfo);
    font-family: Be Vietnam Pro;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    gap: 20px;

    a {
        color: var(--footerinfo) !important;
        font-size: 12px;
        text-decoration: none;
    }
}


.homepage_footer_link {
    list-style-type: none;
    margin: 20% 0;
    display: flex;
    flex-direction: column;
}

.homepage_footer_link li {
    margin: 7% 0;
}

.homepage_footer_link a {
    text-decoration: none;
}

.homepage_footer_socialbtn {
    margin: 10% 0;
    cursor: pointer;
    display: flex;
    gap: 1px;

}

.homepage_footer_down {
    text-align: center;
}

.homepage_footer_down hr {
    border: 0.5px solid var(--footerline);
}

.homepage_footer_down p {
    padding-top: 5%;
}

@media(max-width:1300px) {
    footer {
        padding: 4% 10%;
    }
}

@media(max-width:1150px) {
    .homepage_footerdiv1 {
        img {
            width: 180px;

        }
    }
}

@media(max-width:950px) {
    .homepage_footerdiv1 {
        width: 200px;

        img {
            width: 150px;
            height: auto;

        }
    }

    .homepage_footer_up {
        gap: 3%;
    }

    .homepage_footer_heading {
        font-size: 12px;
    }

    .homepage_footer_info {
        font-size: 10px;
    }


}


@media(max-width:650px) {
    .homepage_footer_up {
        flex-direction: column;
        justify-content: center;
        gap: 20px
    }

    .homepage_footerdiv1,
    .homepage_footerdiv2,
    .homepage_footerdiv3,
    .homepage_footerdiv4 {
        width: 100%;
    }

    .homepage_footer_link {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        margin: 4px 0;

        li {
            margin: 0;
        }
    }

    .homepage_footer_socialbtn {
        margin: 10px 0;
    }

    /* .homepage_footerdiv1 {
        width: 90%;
    }

    .homepage_footerdiv2,
    .homepage_footerdiv3 {
        width: 26%;

    }

    .homepage_footer_up {
        gap: 0
    } */
}