.editbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/editbg.png");

}

.editprofile_data {
    display: flex;
    margin: 4% 18.5%;
    gap: 35px;
}

.editprofile_maintitle {
    font-size: 20px;
    margin: 15px;
}

.editprofile_info {
    width: calc(100% - 210px);

    h1 {
        font-size: 22px;
        margin: 20px 0;
    }
}

.editimg {
    position: absolute;
    bottom: 5px;
    right: 0;
    cursor: pointer
}

.editprofileimgdiv {
    display: flex;
    align-items: center;
    gap: 44px;

    img {
        width: 145px;
        height: 145px;
        border-radius: 50%;
    }

    input[type=file] {
        display: none;
    }
}

.editprofileinfodiv {
    width: calc(100% - 145px)
}

.editprofile_namediv {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.editprofile_title {
    font-size: 14px;
    margin: 10px 0;
}

.editprofile_titlevalue {
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 15px;
    border: none;
    outline: none;
}

.profilemenu {
    width: 218px;
    border-radius: 6px;

}

.profilemenu>li:nth-child(1) {
    border-radius: 6px 6px 0 0;
}

.profilemenu_li {
    list-style-type: none;
    background-color: var(--menubg);
    border-bottom: 1px solid var(--menuborder);
    padding: 12px 15px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--black);
    font-size: 15px;
    gap: 15px;
    cursor: pointer;
}

.selected_profilemenu {
    background-color: var(--black);
    color: var(--white);

    path {
        fill: var(--white) !important;

    }
}

.helpcenter_form {
    width: 100%
}

@media(max-width:1150px) {
    .editprofile_data {
        display: flex;
        margin: 4% 12.5%;
        gap: 35px;
    }
}

@media(max-width:900px) {
    .editprofile_data {
        display: flex;
        margin: 4% 5.5%;
        gap: 35px;
    }

    .editprofile_namediv {
        flex-direction: column;
    }

    .editprofileimgdiv {
        img {
            width: 100px;
            height: 100px;
        }
    }

    .editprofileinfodiv {
        width: calc(100% - 100px)
    }

    .editprofile_title {
        font-size: 12px;
    }

    .editprofile_titlevalue {
        font-size: 15px;
    }


}

@media(max-width:650px) {
    .editprofile_data {
        display: flex;
        margin: 0%;
        gap: 15px;
        height: 432px;
    }

    .profilemenu {
        width: 78px;
        gap: 10px;
        height: 430px;
        overflow-y: scroll;
        border-radius: 0;
    }

    .profilemenu::-webkit-scrollbar {
        width: 0px
    }



    .editprofileimgdiv {
        margin: 35px 0;
        flex-direction: column;

    }

    .editprofileinfodiv {
        width: 95%
    }

    .profilemenu_li {
        flex-direction: column;
        padding: 12px 5px;
        gap: 10px;
        font-size: 10px;
        justify-content: center;
        text-align: center;

        svg {
            width: 20px;
            height: 20px
        }
    }

    .editprofile_maintitle {
        font-size: 18px;
    }


    .editprofile_info {
        height: 100%;
        overflow-y: scroll;
        width: calc(100% - 110px)
    }

    .editprofile_info::-webkit-scrollbar {
        width: 0px
    }

    .profilemenu>li:nth-child(1) {
        border-radius: 0;

    }

}