.payment_value {
    display: flex;
    justify-content: space-between;
    background: url("../../assets/paymentvaluebg.png"), var(--black);
    border-radius: 10px;
    align-items: center;
    color: var(--white);
    font-family: Be vietnam;

}

.payment_value_info {
    margin: 20px;

    h1 {
        font-size: 30px;
        margin: 2px 3%;
    }

    p {
        font-size: 14px;
        color: var(--paymentvalue);
    }
}

.payment_button {
    border: none;
    border-radius: 6px;
    color: var(--black);
    background-color: var(--white);
    height: 37px;
    margin: 20px 3%;
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;

}

.payment_cards {
    h3 {
        font-size: 16px;
        margin: 15px 10px;
    }
}

.payment_allcards {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    margin: 20px 0;
}

.payment_cardinfo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--inputborder);
    gap: 15px;
    width: 44.3%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;

    img {
        width: 33px;
        border: 1px solid var(--inputborder);
        border-radius: 5px;
        padding: 5px;
    }
}

.cardmenuoption {
    position: absolute;
    right: 2%;
    top: 75%;
    background-color: white;
    border: 1px solid var(--inputborder);
    padding: 10px;
    border-radius: 10px;
    z-index: 10000000;
    cursor: pointer;
}

.payment_cardselected {
    display: flex;
    align-items: center;
}

.payment_unselected {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid var(--black);
    cursor: pointer;
}

.payment_cardadd {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    margin: 10px;
    cursor: pointer;
}

@media(max-width:1350px) {
    .payment_cardinfo {
        width: 85%;
        height: 30px;
    }
}

@media(max-width:650px) {
    .payment_cardinfo {
        width: 100%;
        height: 30px;
    }
}

@media(max-width:450px) {

    .payment_cardinfo {
        font-size: 12px;

        img {
            width: 23px
        }
    }
}

@media(max-width:350px) {


    .payment_cardinfo {
        font-size: 12px;
        /* flex-direction: column; */
        height: auto;

        img {
            width: 23px
        }
    }
}