.Bookconfirmrdiv {
    text-align: center;
    margin: 1.4% 0;

    h1 {
        font-size: 20px;
        margin: 15px 0;
    }

    p {
        font-size: 13px;
        color: var(--confirmtext);
        margin: 10px 20px;
    }
}