.caregiverbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/bookcaretaker.png");

}

.caregiverbg2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Bookacaregiver2.png");

}

.caregiverbg3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Bookacaregiver3.png");

}

.Bookcaregiverdiv {
    display: flex;
    align-items: flex-start;
    margin: 0.5% 18%;
    gap: 100px;
}

.nodata {
    font-size: 13px;
    margin: 20px 10px;
}

.Bookcaregiverdiv_left {
    width: 65%;

    h1 {
        font-size: 21px;
        margin: 15px 0;

    }

    h2 {
        font-size: 18px;
        margin: 20px 0;
    }

}

.Bookcaregiver_info {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.Bookcaregiverimg {
    margin-top: 70px;
    width: 35%;
    height: auto
}

.Bookcaregiver_inputfield_divs {
    display: flex;
    width: 100%;
    gap: 35px
}

.Bookcaregiver_inputfield {
    margin: 9px 0;
    width: 100%;

    label {
        font-size: 14px;
    }
}

.Bookcaregiver_input {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    height: 42px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 0 10px;
    width: calc(100% - 20px);
    background: transparent;
    outline: none;
    font-size: 14px;
}

.cardelementinput {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    height: 22px;
    margin-top: 10px;
    padding: 10px;
    width: 99%;
    font-size: 14px;

}

.Bookcaregiver_input::placeholder {
    color: var(--inputtext);
}

.Bookcaregiver_button {
    border: none;
    border-radius: 6px;
    background-color: var(--black);
    color: var(--white);
    height: 40px;
    padding: 5px 23px;
    margin: 15px 0;
    font-size: 15px;
    cursor: pointer;

}

.Bookcaregiver_prvbutton {
    border: 1px solid var(--black);
    border-radius: 6px;
    background-color: var(--white);
    color: var(--black);
    height: 40px;
    padding: 5px 23px;
    margin: 15px 0;
    font-size: 15px;
    cursor: pointer;

}

.Bookcaregiver_check {
    margin: 15px 0;
    display: flex;
    gap: 10px;

    label>span {
        font-weight: bold;
        cursor: pointer;
    }
}

.Bookcaregiver_check2 {
    margin: 8px 25px;
    display: flex;
    gap: 10px;

    label>span {
        font-weight: bold;
        cursor: pointer;
    }
}

.Bookcaregiver_service {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.Bookcaregiver_check_label {
    font-size: 13px;

}

.Bookcaregiver_cost {
    font-size: 17px;
    margin-bottom: 12px;

}

.Bookcaregiver_btngrp {
    display: flex;
    gap: 15px;
}

.Bookcaregiver_timslot {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
    margin: 15px 0;

    li {
        list-style-type: none;
        padding: 10px 30px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        color: var(--sec4grey);
        font-size: 13px;
        cursor: pointer;

    }

    li:hover {
        background-color: var(--black);
        color: var(--white);

    }
}

.selctedtimeslot {
    background-color: var(--black);
    color: var(--white) !important;

}

.Bookcaregiver_servicename {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px;
    color: var(--landingsec1text);
    border-bottom: 1px solid var(--landingsec1text);
    cursor: pointer;
}

.Bookcaregiver_servicetype {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    padding: 5px;
    color: var(--landingsec1text);
}

/* ------------------calendar-------------------- */

.react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: var(--white);
    font-family: Be vietnam Pro;
    color: var(--black);
    line-height: 1.125em;
    border: None !important;
}

.react-calendar__navigation button {
    color: var(--black);
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--white) !important;
}

.react-calendar__navigation button[disabled] {
    background-color: var(--white) !important;
    color: #d5d5d5;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--black) !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
    color: #ababab !important;
}

.react-calendar__tile:disabled {
    background-color: var(--white) !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: var(--inputtext) !important;
    color: var(--white) !important;

}

.selecteddate {
    background: var(--black) !important;
    color: var(--white) !important;
}

.react-calendar__tile--now {
    background: var(--white);
    color: var(--black);
    border-radius: 6px;
    font-weight: bold;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--inputtext) !important;
    color: var(--white) !important;
    border-radius: 6px;
    font-weight: bold;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--black) !important;
    color: white !important;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
    background: var(--black) !important;
    font-weight: bold;
    color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--black) !important;
    color: white !important;
}


.react-calendar__tile--now.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: var(--black) !important;
    color: white !important;

}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: var(--black) !important;
    color: white !important;

}

/* ----------------coupen code------------------- */

.allcoupenlist {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0
}

.coupen_list {
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 90%;

}

.coupen_view {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    align-items: center;
    margin: 8px 3px;

}

.coupen_title {
    font-size: 17px !important;
    margin: 10px 6px !important;

}

.coupen_min {
    font-size: 14px !important;
    margin: 10px 6px !important;

}

.coupen_details {
    font-size: 12px;
    padding: 10px 20px;
    border-top: 1px dashed #ccc;
}

.coupen_name {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px;
}

.coupen_apply:disabled {
    color: #b4b3b3 !important;

}

.coupen_apply {
    background-color: #dfdcdc;
    color: var(--black);
    width: 100%;
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;

}

.coupen_viewhide {
    cursor: pointer;

}

@media(max-width:1050px) {
    .Bookcaregiverdiv {
        margin: 0.5% 15%;
    }

}

@media(max-width:900px) {

    .Bookcaregiverdiv {
        margin: 0.5% 10%;
    }

    .Bookcaregiver_inputfield_divs {
        flex-direction: column;
        gap: 3px;
    }

}

@media(max-width:650px) {
    .Bookcaregiverimg {
        display: none;
    }

    .Bookcaregiverdiv_left {
        width: 100%
    }



    .Bookcaregiver_inputfield {
        margin: 6px 0;

        label {
            font-size: 13px;
        }
    }

    .Bookcaregiver_input {
        height: 38px;
        font-size: 13px;
    }


    .Bookcaregiver_button {
        font-size: 14px;
    }

    .Bookcaregiverdiv_left>h2 {
        font-size: 17px;
        margin: 15px 0;

    }

    .react-calendar {
        width: 200px;
        line-height: 1em;
    }


}