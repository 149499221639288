.Scanner_input {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.note_mark_title {
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
}

.note_mark {
    font-size: 11px;
    margin: 0 20px;
}

.otp_input {
    width: 45px !important;
    height: 45px !important;
    background-color: var(--white);
    outline: none;
    border: 1px solid var(--black);
    border-radius: 6px;
    margin: 3px !important;
    font-size: 18px;
    text-align: center;
}

.otp_input::-webkit-outer-spin-button,
.otp_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp_input:disabled {
    border: 1px solid var(--inputborder);

}

.Voucher_button {
    display: block;
    border-radius: 6px;
    background: var;
    padding: 12px 0;
    width: 85%;
    margin: 15px auto;
    border: none;
    outline: none;
    font-size: 15px;
    color: var(--white);
    cursor: pointer;

}

.Scanner_title {
    font-size: 25px;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    border-radius: 10px 10px 0 0;
    background-color: var(--bgcolor);
}

.Scanner_data_list {
    display: flex;
    justify-content: space-between;
    margin: 30px 50px;

}

.Scanner_data {
    p {
        font-size: 13px;
        color: var(--greyfont);
    }

    h2 {
        font-size: 15px;
    }
}

@media(max-width:560px) {
    .otp_input {
        width: 35px !important;
        height: 35px !important
    }

}