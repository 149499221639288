.becamecaregiverbg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Becamecaretaker1.png");

}

.becamecaregiverbg2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Becamecaretaker2.png");

}

.becamecaregiverbg3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Becamecaretaker3.png");

}

.DailyAvailabilitybg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)), url("../../assets/Becamecaretaker4.png");

}

.Becamecare_button {
    border: 1px solid var(--inputborder);
    border-radius: 6px;
    background-color: var(--white);
    height: 42px;
    margin-top: 10px;
    padding: 0 10px;
    width: 99%;
    outline: none;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: var(--green);
        color: var(--white);
        border: none;
    }
}

.selected_btn {
    background-color: var(--green);
    color: var(--white);
    border: none;

}

.becamecare_info {
    font-size: 14px;
    margin: 10px 0;
}

.Becomecare_profileimg {
    width: 136px;
    height: 136px;
    position: relative;
    margin: 10px auto;
    cursor: pointer;
}

.Becomecareprofile_previewimg {
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.Becomecare_profileimg>input[type="file"],
.Becomecare_uploadimg input[type="file"] {
    display: none;
}

.Becomecareprofile_img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: relative;

}

.Becomecaredelete_img {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.Becomecare_uploadimg {
    width: 100%;
    height: 170px;
    border-radius: 6px;
    border: 1px dashed var(--uploadborder);
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Becomecare_uploadtitle {
    font-size: 15px;
    margin-top: 20px;

}

.becamecare_uploadbtn {
    text-align: center;
    cursor: pointer;

    img {
        width: 52px;
    }

    p {
        color: var(--inputtext);

        span {
            color: var(--green);
        }
    }
}

.days {
    display: flex;
    font-size: 13px;
    font-weight: 600;
    gap: 5px;
    margin: 10px 0;

}

.daysname {
    border: 1px solid var(--black);
    padding: 7px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;

}

.selectedday {
    background-color: var(--black);
    color: var(--white);
}

.kyctitle {
    font-size: 20px;
    margin: 15px;
}

.kycdes {
    font-size: 14px;
    margin: 5px 10px;
}

.kycbuttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 15px;

    button {
        background-color: var(--white);
        border: 1px solid var(--black);
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;

        &&:hover {
            background-color: var(--black);
            color: var(--white);
        }
    }
}

@media(max-width:650px) {
    .becamecare_info {
        font-size: 13px;
    }

}